<template>
  <div class="card">
    <Toast position="top-center" group="info" />
    <h5>Deposits</h5>
    <ProgressBar
      v-if="loading"
      class="progress-bar"
      mode="indeterminate"
    />
    <DataTable
      v-else
      :value="paysData"
      data-key="id"
      paginator
      lazy
      :rows="itemsPerPage"
      :total-records="totalItems"
      @page="changePage"
    >
      <Column header="User" field="user.email">
        <template #body="slot">
          <span
            class="clickable"
            @click="goToUser(slot.data?.user?.id)"
          >
            {{ slot.data?.user?.email ?? "-" }}
          </span>
        </template>
      </Column>
      <Column header="Token" field="auToken.nameEng" />
      <Column header="Address" field="address" />
      <Column header="Confirmed Value" field="confirmedValue">
        <template #body="slot">
          {{ formatValue(slot.data.confirmedValue) }}
        </template>
      </Column>
      <Column header="Date" field="createdAt">
        <template #body="slot">
          {{ formatDate(slot.data.createdAt) }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import moment from 'moment/moment'
import BigNumber from 'bignumber.js'

export default {
  name: "ManagerPays",
  data() {
    return {
      loading: false,
      page: 1,
      itemsPerPage: 30,
      paysData: [],
      totalItems: 0
    }
  },

  async mounted() {
    await this.getPaysData();
  },

  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm ")
    },

    formatValue(value) {
      const ret = BigNumber(value);
      return ret.isNaN() ? "-" : ret.toString(10);
    },

    async changePage(event) {
      this.page = event.page + 1;
      await this.getPaysData()
    },

    goToUser(id) {
      this.$router.push({ name: "MarketingUsers", params: { id: id }});
    },

    async getPaysData() {
      this.loading = true;
      const headers = {
        accept: "application/ld+json",
        "Content-Type": "application/ld+json",
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage
      }
      try {
        const resp = await this.axios.get("/api/pays", { params, headers });
        this.paysData = resp.data["hydra:member"];
        this.totalItems = resp.data["hydra:totalItems"];
      }
      catch (e) {
        this.showError("Error when receiving payment list");
      }
      this.loading = false;
    },
  }
}
</script>

<style scoped>

</style>